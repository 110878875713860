import React from "react";
import "../stylesheets/layout/beerSheet/Videos.scss";
import whiteArrow from "../icons/icono-flecha-blanca.svg";

class DescriptionVideos extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <section className="videos conntent-videos" id="videosLink">
        {this.props.filterItem.length > 0 ? (
          <div>
            {this.props.titlesFilter.map((i, index) => {
              return (
                <div key={index}>
                  <button className="styleBeer__title-content">
                    <h2 className="videos__title">{i.Videos}</h2>
                  </button>
                  <p className="videos__text">{i.InfoVideos} </p>
                </div>
              );
            })}
            <div className="videos__link-container">
              <a
                href={this.props.filterItem[0].Video1Link}
                target="_blank"
                rel="noopener noreferrer"
                className="videos__link-url"
              >
                Ver vídeo
              </a>
            </div>
          </div>
        ) : null}
      </section>
    );
  }
}

export default DescriptionVideos;
