import React from "react";
import "../stylesheets/layout/beerSheet/Download.scss";
import downloadIcon from "../icons/icono-descarga.svg";

const Download = (props) => {
  //console.log(`propiedades download ${props.downloadPDF}`);
  return (
    <div
      className={props.burguerMenu === true ? "download-mobile" : "download"}
    >
      {props.titlesFilter.map((i, index) => {
        return (
          <button className="download__btn" key={index}>
            <img
              className="download__icon"
              src={downloadIcon}
              alt="icono descarga"
              height="30"
            />
            <a className="download__btn-link" onClick={props.downloadPDF}>
              {i.Download}
            </a>
          </button>
        );
      })}
      <div></div>
    </div>
  );
};

export default Download;
