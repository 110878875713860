import React from "react";
import { Link } from "react-router-dom";
import MenuLanguages from "./MenuLanguages";
import MenuDownload from "./MenuDownload";
import MenuItems from "./MenuItems";
import "../stylesheets/layout/beerSheet/Menu.scss";
import arrowIcon from "../icons/icono-flecha.svg";
import burguerMenuImage from "../icons/icono-hamburguesa.svg";

class Menu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  
  componentDidMount() {}

  render() {
    const {
      burguerMenu,
      languages,
      genPDF,
      downloadPDF,
      moreDataDetail,
      titlesFilter,
      beerDetail,
      prizesDetail
    } = this.props;

   

    return (
      <div className="menu menu__mobile">
        <div className="menu__back-container">
          {this.props.titlesFilter.map((i, index) => {
            return (
              <Link className="menu__back-btn" to="/">
                {" "}
                <img
                  className="menu__back-arrow"
                  src={arrowIcon}
                  alt="icono flecha"
                />{" "}
                {i.Back}
              </Link>
            );
          })}

          <div className={burguerMenu === true ? "hidden" : "menu__content"}>
            <button
              className="menu__burguer-btn"
              onClick={this.props.toggleMenuBtn}
            >
              <img
                className="menu__burguer-icon"
                src={burguerMenuImage}
                alt="menu hamburguesa"
              />
            </button>
          </div>
        </div>
        <div className={burguerMenu === true ? "mobile__wrap" : null}>
          <MenuLanguages
            burguerMenu={burguerMenu}
            changeLanguage={this.props.changeLanguage}
            languages={languages}
            moreDataDetail={moreDataDetail}
          />
          <MenuDownload
            burguerMenu={burguerMenu}
            genPDF={genPDF}
            downloadPDF={downloadPDF}
            titlesFilter={titlesFilter}
          />
          <MenuItems
            burguerMenu={burguerMenu}
            titlesFilter={titlesFilter}
            beerDetail={beerDetail}
            moreDataDetail={moreDataDetail}
            prizesDetail={prizesDetail}
            //handleCollapsibleMenu={this.props.handleCollapsibleMenu}
            //getCollapsibleDescription={this.props.getCollapsibleDescription}
          />
        </div>
      </div>
    );
  }
}

export default Menu;
