import React from "react";
import noImage from "../images/noImagew.png";
import { removeTags } from "./StringFormatter";

import Collapsible from "./Collapsible";
import Spinner from "./Spinner";
import DescriptionVideos from "./DescriptionVideos";


import "../stylesheets/layout/beerSheet/Description.scss";
import "../stylesheets/layout/beerSheet/Title.scss";
import "../stylesheets/layout/beerSheet/Main.scss";
import "../stylesheets/layout/beerSheet/technicalData.scss";
import "../stylesheets/layout/beerSheet/StyleBeer.scss";
import "../stylesheets/layout/beerSheet/More.scss";
import "../stylesheets/layout/beerSheet/Awards.scss";

import iconoAgua from "../images/materias_primas/icono_agua.svg";
import iconoMas from "../images/materias_primas/icono_mas.svg";


var rutaApi = window.ENV.urlBack;
let releaseDate;



class DescriptionWater extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentcategory: "",
    };
    releaseDate = new Date(this.props.beerDetail.FechaLanzamiento);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  //in your component
  addDefaultSrc(ev) {
    ev.target.src = noImage;

    // var parent = ev.target.parentElement; //parent of "target"
    // var grandParent = parent.parentElement;
    // var grandGrandParent = grandParent.parentElement;
    var compassHidden = document.getElementById("compass_js");
    compassHidden.className = "hidden-compass";
  }

  render() {

    const {
      beerDetail,
      beersSegments,
      moreDataDetail,
      langSelect,
      titlesFilter,
      prizesDetail,
      brands,
      segmentActive,
      segmentName,
    } = this.props;

    const filterItem = this.props.moreDataDetail.filter(
      (item) => item.Language === this.props.langSelect
    ); //filtrado de idiomas

    const imageBeer = `${rutaApi}/blob.ashx?beersProductImageContent=o|${beerDetail.ID}`;

    console.log(moreDataDetail);

    const filterPhoto = this.props.photosDetail.filter(
      (i) => i.PhotoType === "Estilo"
    );
    const filterLogo = this.props.brands.filter(
      (i) => i.ID === this.props.beerDetail.BrandId
    );
    const filterPerfectPhoto = this.props.photosDetail.filter(
      (i) => i.PhotoType === "Perfect Serve"
    );
    const filterSpainCompass = this.props.moreDataDetail.filter(
      (item) => item.Language === "es"
    ); //filtrado de idiomas

    return (
      <main
        className={this.props.burguerMenu === true ? "dark" : "description"}
        onClick={this.props.closeMenu}
      >
        <div id="newID" style={{ opacity: this.props.showSpinner ? 0.5 : 1 }}>
          <Spinner showSpinner={this.props.showSpinner} />
          {/* {moreDataDetail.length > 0 && filterItem.length > 0 ? ( */}

          <div className="title">
            <h1 className="title__name">{beerDetail.Name}</h1>
          </div>

          {titlesFilter.map((i, index) => {
            return (
              <div>
                <div className="main-wrapper">
                  {(filterItem[0] !== undefined &&
                    filterItem[0].hasOwnProperty("Claim")) ||
                    (filterItem[0] !== undefined &&
                      filterItem[0].hasOwnProperty("Descripcion")) ? (
                    filterItem[0]["Claim"] != "" ||
                      filterItem[0]["Descripcion"] != "" ? (
                      <section className="main">
                        {/* CLAIM */}
                        <div className="main__section-content" key={index}>
                          {releaseDate.getFullYear() === 1900 ||
                            this.props.beerDetail.FechaLanzamiento ===
                            undefined ||
                            releaseDate.getFullYear() === 1970 ? (
                            ""
                          ) : (
                            <p id="mainDate" className="main__date">
                              {i.Lanzamiento}:{" "}
                              <span className="main__date date-number">
                                {" "}
                                {releaseDate.getFullYear()}
                              </span>
                            </p>
                          )}
                          <h3 id="mainTitle" className="main__title">
                            {removeTags(filterItem[0].Claim)}
                          </h3>
                          <div className="main__brand-content">
                            <p id="mainText" className="main__text">
                              {removeTags(filterItem[0].Descripcion)}
                            </p>
                            {filterLogo.map((i, index) => {
                              return (
                                <img
                                  key={index}
                                  className="styleBeer__list-logo"
                                  src={
                                    `${rutaApi}/blob.ashx?brandsLogoContent=o|${i.ID}&_nocrop`
                                  }
                                  alt="logotipo de la marca"
                                />
                              );
                            })}
                          </div>
                        </div>
                      </section>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  <div className="main__images-content">
                    <img
                      className="main__image-front"
                      src={imageBeer}
                      alt="muestra frontal de la cerveza"
                    />
                  </div>
                </div>
                {/* DATOS TÉCNICOS */}
                {beerDetail.Bicarbonatos === undefined &&
                  beerDetail.Potasio === undefined &&
                  beerDetail.Magnesio === undefined &&
                  beerDetail.Sodio === undefined &&
                  beerDetail.Calcio === undefined &&
                  beerDetail.Alcohol === undefined &&
                  beerDetail.Amargo === undefined &&
                  beerDetail.Color === undefined &&
                  beerDetail.Extracto === undefined ? (
                  ""
                ) : (
                  <section className="technical" id="my-table">
                    <div key={index}>
                      <Collapsible
                        open={
                          this.state.open &&
                          this.state.currentcategory === "technical"
                        }
                        title={i.Minerals}
                        className="technical__title"
                      >
                        <ul className="technical__list">
                          {beerDetail.Bicarbonatos ? (
                            <li className="technical__item first">
                              <span className="technical__item-number">
                                {beerDetail.Bicarbonatos}
                              </span>
                              <p className="technical__item-text">
                                {i.Bicarbonates} (mg/l)
                              </p>
                            </li>
                          ) : (
                            ""
                          )}
                          {beerDetail.Potasio ? (
                            <li className="technical__item first">
                              <span className="technical__item-number">
                                {beerDetail.Potasio}
                              </span>
                              <p className="technical__item-text">
                                {i.Potassium} (mg/l)
                              </p>
                            </li>
                          ) : (
                            ""
                          )}
                          {beerDetail.Magnesio ? (
                            <li className="technical__item first">
                              <span className="technical__item-number">
                                {beerDetail.Magnesio}
                              </span>
                              <p className="technical__item-text">
                                {i.Magnesium} (mg/l)
                              </p>
                            </li>
                          ) : (
                            ""
                          )}
                          {beerDetail.Sodio ? (
                            <li className="technical__item first">
                              <span className="technical__item-number">
                                {beerDetail.Sodio}
                              </span>
                              <p className="technical__item-text">
                                {i.Sodium} (mg/l)
                              </p>
                            </li>
                          ) : (
                            ""
                          )}
                          {beerDetail.Calcio ? (
                            <li className="technical__item first">
                              <span className="technical__item-number">
                                {beerDetail.Calcio}
                              </span>
                              <p className="technical__item-text">
                                {i.Calcium} (mg/l)
                              </p>
                            </li>
                          ) : (
                            ""
                          )}
                          {beerDetail.Alcohol ? (
                            <li className="technical__item first">
                              <span className="technical__item-number">
                                {/* {parseFloat(beerDetail.Alcohol)
                                  .toFixed(1)
                                  .replace(".", ",")} */}
                                {beerDetail.Alcohol.length > 1
                                  ? beerDetail.Alcohol
                                  : beerDetail.Alcohol + ",0"}
                              </span>
                              <p className="technical__item-text">
                                {i.Alcohol} (%V/V)
                              </p>
                            </li>
                          ) : (
                            ""
                          )}
                          {beerDetail.Amargo ? (
                            <li className="technical__item first">
                              <span className="technical__item-number">
                                {beerDetail.Amargo}
                              </span>
                              <p className="technical__item-text">
                                {i.Bitterness} (IBU)
                              </p>
                            </li>
                          ) : (
                            ""
                          )}
                          {beerDetail.Color ? (
                            <li className="technical__item first">
                              <span className="technical__item-number">
                                {beerDetail.Color}
                              </span>
                              <p className="technical__item-text">
                                {i.Color} (EBC)
                              </p>
                            </li>
                          ) : (
                            ""
                          )}
                          {beerDetail.Extracto ? (
                            <li className="technical__item first">
                              <span className="technical__item-number">
                                {beerDetail.Extracto}
                              </span>
                              <p className="technical__item-text">
                                {i.OriginalExtract} (⁰P)
                              </p>
                            </li>
                          ) : (
                            ""
                          )}
                        </ul>
                      </Collapsible>
                    </div>
                  </section>
                )}
                {/* MATERIAS PRIMAS  --OK*/}
                {(filterItem[0] !== undefined &&
                  filterItem[0].hasOwnProperty("Agua")) ||
                  (filterItem[0] !== undefined &&
                    filterItem[0].hasOwnProperty("Malta")) ||
                  (filterItem[0] !== undefined &&
                    filterItem[0].hasOwnProperty("Lupulo")) ||
                  (filterItem[0] !== undefined &&
                    filterItem[0].hasOwnProperty("Levadura")) ||
                  (filterItem[0] !== undefined &&
                    filterItem[0].hasOwnProperty("Otros")) ? (
                  filterItem[0]["Agua"] != "" ||
                    filterItem[0]["Malta"] != "" ||
                    filterItem[0]["Lupulo"] != "" ||
                    filterItem[0]["Levadura"] != "" ||
                    filterItem[0]["Otros"] != "" ? (
                    <section className="materials">
                      <div key={index}>
                        <div>
                          <Collapsible
                            open={
                              this.state.open &&
                              this.state.currentcategory === "materials"
                            }
                            title={i.Composition}
                            className="materials__title"
                          >
                            <ul className="materials__list">
                              {filterItem[0].Agua &&
                                <li>
                                  <div>
                                    <img className="materials__icon" src={iconoAgua} alt={i.Water} />
                                    <h5 className="materials__item-title">
                                      {filterItem[0].Agua ? i.Water : ""}
                                    </h5>
                                    <p className="materials__item-subtitle">
                                      {removeTags(filterItem[0].Agua)}
                                    </p>
                                  </div>
                                </li>
                              }
                              {filterItem[0].Otros &&
                                <li
                                >
                                  <div>
                                    <img
                                      className="materials__icon" src={iconoMas} alt={i.Others}
                                    />
                                    <h5 className="materials__item-title">
                                      {filterItem[0].Otros ? i.Others : ""}
                                    </h5>
                                    <p className="materials__item-subtitle">
                                      {removeTags(filterItem[0].Otros)}
                                    </p>
                                  </div>
                                </li>
                              }
                            </ul>
                          </Collapsible>
                        </div>
                      </div>
                    </section>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}

                {/* A DESTACAR */}
                {filterItem[0] !== undefined &&
                  filterItem[0].hasOwnProperty("Adestacar") ? (
                  filterItem[0]["Adestacar"] != "" ? (
                    <section className="highlight">
                      <Collapsible
                        open={
                          this.state.open &&
                          this.state.currentcategory === "highlight"
                        }
                        title={i.Highlight}
                        className="highlight__title"
                      >
                        <div className="padding-content">
                          <p className="highlight__text">
                            {removeTags(filterItem[0].Adestacar)}
                          </p>
                        </div>
                      </Collapsible>
                    </section>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}

                {/* NOTA DE CATA -- OK */}
                {(filterItem[0] !== undefined &&
                  filterItem[0].hasOwnProperty("NotaCataApariencia")) ||
                  (filterItem[0] !== undefined &&
                    filterItem[0].hasOwnProperty("NotaCataSabor")) ? (
                  filterItem[0]["NotaCataApariencia"] != "" ||
                    filterItem[0]["NotaCataSabor"] != "" ? (
                    <section className="tasting">
                      <Collapsible
                        open={
                          this.state.open &&
                          this.state.currentcategory === "tasting"
                        }
                        title={i.Tasting}
                        className="tasting__title"
                      >
                        <ul className="tasting__list">
                          <li
                            className={
                              filterItem[0].NotaCataApariencia
                                ? "tasting__item"
                                : "list-none"
                            }
                          >
                            <div>
                              <h5 className="tasting__item-title">
                                {filterItem[0].NotaCataApariencia
                                  ? i.Appearance
                                  : ""}
                              </h5>
                              <p className="tasting__item-subtitle">
                                {removeTags(filterItem[0].NotaCataApariencia)}
                              </p>
                            </div>
                          </li>
                          <li
                            className={
                              filterItem[0].NotaCataSabor
                                ? "tasting__item"
                                : "list-none"
                            }
                          >
                            <div>
                              <h5 className="tasting__item-title">
                                {filterItem[0].NotaCataSabor ? i.Flavor : ""}
                              </h5>
                              <p className="tasting__item-subtitle">
                                {removeTags(filterItem[0].NotaCataSabor)}
                              </p>
                            </div>
                          </li>
                        </ul>
                      </Collapsible>
                    </section>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}

                {/* MARIDAJE  --OK*/}
                {(filterItem[0] !== undefined &&
                  filterItem[0].hasOwnProperty("MaridajeGeneral")) ||
                  (filterItem[0] !== undefined &&
                    filterItem[0].hasOwnProperty("MaridajeEntrante")) ||
                  (filterItem[0] !== undefined &&
                    filterItem[0].hasOwnProperty("MaridajePrimero")) ||
                  (filterItem[0] !== undefined &&
                    filterItem[0].hasOwnProperty("MaridajeSegundo")) ||
                  (filterItem[0] !== undefined &&
                    filterItem[0].hasOwnProperty("MaridajePostre")) ? (
                  filterItem[0]["MaridajeGeneral"] != "" ||
                    filterItem[0]["MaridajeEntrante"] != "" ||
                    filterItem[0]["MaridajePrimero"] != "" ||
                    filterItem[0]["MaridajeSegundo"] != "" ||
                    filterItem[0]["MaridajePostre"] != "" ? (
                    <section className="pairing">
                      <Collapsible
                        open={
                          this.state.open &&
                          this.state.currentcategory === "pairing"
                        }
                        title={i.Pairing}
                        className="pairing__title"
                      >
                        <div className="padding-content">
                          <p className="pairing__text">
                            {filterItem[0].MaridajeGeneral
                              ? filterItem[0].MaridajeGeneral
                              : ""}
                          </p>
                          <ul className="pairing__list">
                            <li
                              className={
                                filterItem[0].MaridajeEntrante
                                  ? "pairing__item"
                                  : "list-none"
                              }
                            >
                              <div>
                                <h5 className="pairing__item-title">
                                  {filterItem[0].MaridajeEntrante
                                    ? i.Incoming
                                    : ""}
                                </h5>
                                <p className="pairing__item-subtitle">
                                  {removeTags(filterItem[0].MaridajeEntrante)}
                                </p>
                              </div>
                            </li>
                            <li
                              className={
                                filterItem[0].MaridajePrimero
                                  ? "pairing__item"
                                  : "list-none"
                              }
                            >
                              <div>
                                <h5 className="pairing__item-title">
                                  {filterItem[0].MaridajePrimero
                                    ? `${i.First} ${i.Plate}`
                                    : ""}
                                </h5>
                                <p className="pairing__item-subtitle">
                                  {removeTags(filterItem[0].MaridajePrimero)}
                                </p>
                              </div>
                            </li>
                            <li
                              className={
                                filterItem[0].MaridajeSegundo
                                  ? "pairing__item"
                                  : "list-none"
                              }
                            >
                              <div>
                                <h5 className="pairing__item-title">
                                  {filterItem[0].MaridajeSegundo
                                    ? `${i.Second} ${i.Plate}`
                                    : ""}
                                </h5>
                                <p className="pairing__item-subtitle">
                                  {removeTags(filterItem[0].MaridajeSegundo)}
                                </p>
                              </div>
                            </li>
                            <li
                              className={
                                filterItem[0].MaridajePostre
                                  ? "pairing__item"
                                  : "list-none"
                              }
                            >
                              <div>
                                <h5 className="pairing__item-title">
                                  {filterItem[0].MaridajePostre
                                    ? i.Desserts
                                    : ""}
                                </h5>
                                <p className="pairing__item-subtitle">
                                  {removeTags(filterItem[0].MaridajePostre)}
                                </p>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </Collapsible>
                    </section>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
                {/* PERFECT SERVE --OK */}
                {filterItem[0] !== undefined ? (
                  (filterItem[0].length > 0 &&
                    filterItem[0].hasOwnProperty("PerfectServeGeneral")) ||
                    (filterItem[0] !== undefined &&
                      filterItem[0].hasOwnProperty("PerfectServeGeneral")) ||
                    beerDetail.hasOwnProperty("PerfectServeTempMin") ||
                    beerDetail.hasOwnProperty("PerfectServeTempMax") ? (
                    filterItem[0]["PerfectServeGeneral"] != "" ||
                      beerDetail["PerfectServeTempMin"] != "" ||
                      beerDetail["PerfectServeTempMax"] != "" ? (
                      <section className="perfect">
                        <Collapsible
                          open={
                            this.state.open &&
                            this.state.currentcategory === "perfect"
                          }
                          title={i.Perfect}
                          className="perfect__title"
                        >
                          <div className="padding-content perfect__container">
                            <div>
                              <p className="perfect__item-subtitle">
                                {removeTags(filterItem[0].PerfectServeGeneral)}
                              </p>
                            </div>
                            <ul className="perfect__list">
                              <li
                                className={
                                  beerDetail.PerfectServeTempMin ||
                                    beerDetail.PerfectServeTempMax
                                    ? "perfect__item"
                                    : "list-none"
                                }
                              >
                                <div>
                                  <h5 className="perfect__item-title">
                                    {beerDetail.PerfectServeTempMin ||
                                      beerDetail.PerfectServeTempMax
                                      ? i.Temperature
                                      : ""}
                                  </h5>
                                  <p className="perfect__item-subtitle">
                                    {beerDetail.PerfectServeTempMin
                                      ? `${beerDetail.PerfectServeTempMin}- `
                                      : ""}
                                    {beerDetail.PerfectServeTempMax
                                      ? `${beerDetail.PerfectServeTempMax}⁰C`
                                      : ""}
                                  </p>
                                </div>
                              </li>
                            </ul>
                            {filterPerfectPhoto.length ? (
                              <div className="perfect__container-img">
                                {filterPerfectPhoto.map((i, index) => {
                                  return (
                                    <img
                                      key={index}
                                      className="perfect__image"
                                      src={`${rutaApi}/blob.ashx?beersPhotosPhotoContent=o|${i.ID}`}
                                      alt="Cómo servir la cerverza"
                                    />
                                  );
                                })}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </Collapsible>
                      </section>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
                {/* PREMIOS */}
                {prizesDetail.length > 0 ? (
                  <section className="awards">
                    <Collapsible
                      open={
                        this.state.open &&
                        this.state.currentcategory === "awards"
                      }
                      title={i.Awards}
                      className="awards__title"
                    >
                      <div className="padding-content">
                        <ul className="awards__list">

                          {prizesDetail.map((prize, index) => {
                            return (
                              <li className="awards__item" key={index}>
                                <img
                                  className="awards__image"
                                  src={
                                    `${rutaApi}/blob.ashx?prizesPrizeFileContent=o|${prize.ID}&`
                                  }
                                  alt="premio"
                                />
                                <h5 className="awards__item-title">
                                  {prize.Name}
                                </h5>
                                <p className="awards__item-subtitle">
                                  {prize.Text}
                                </p>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </Collapsible>
                  </section>
                ) : (
                  ""
                )}

                {/* INFORMACIÓN ADICCIONAL */}
                {filterItem[0] !== undefined &&
                  filterItem[0].hasOwnProperty("InfoAdicional") ? (
                  filterItem[0]["InfoAdicional"] != "" ? (
                    <section className="additional">
                      <Collapsible
                        open={
                          this.state.open &&
                          this.state.currentcategory === "additional"
                        }
                        title={i.Additional}
                        className="additional__title"
                      >
                        <div className="padding-content">
                          <p className="additional__text">
                            {removeTags(filterItem[0].InfoAdicional)}
                          </p>
                        </div>
                      </Collapsible>
                    </section>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
                {/* VIDEOS */}
                {filterItem[0] !== undefined &&
                  filterItem[0].hasOwnProperty("Video1Link") ? (
                  filterItem[0]["Video1Link"] != "" ? (
                    <DescriptionVideos
                      filterItem={filterItem}
                      moreDataDetail={moreDataDetail}
                      langSelect={langSelect}
                      titlesFilter={titlesFilter}
                    />
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </div>
            );
          })}
        </div>
      </main>
    );
  }
}

export default DescriptionWater;
