import React from "react";
import CollapsibleSearch from "./CollapsibleSearch";
import CollapsibleGroups from "./CollapsibleGroups";
import arrowIcon from "../icons/icono-flecha.svg";
import "../stylesheets/layout/home/Search.scss";
import lupa from "../icons/icono-lupa.svg";

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      openGroups: false,
    };
    this.toggle = this.toggle.bind(this);
    this.toggleGroups = this.toggleGroups.bind(this);
    this.deleteButton = this.deleteButton.bind(this);
    this.deleteButtonGroups = this.deleteButtonGroups.bind(this);
  }

  toggle() {
    this.setState((prevState) => {
      return {
        open: !prevState.open,
      };
    });
  }
  toggleGroups() {
    this.setState((prevState) => {
      return {
        openGroups: !prevState.openGroups,
      };
    });
  }
  deleteButton = function () {
    const selectButton = document.getElementsByClassName("brands__btn");
    this.props.deleteSearch(selectButton);
  };
  deleteButtonGroups = function () {
    const groupsButton = document.getElementsByClassName("groups__btn");
    this.props.deleteSearchGroups(groupsButton);
  };
  render() {
    const {
      handleTextFilter,
      search,
      brands,
      handleSelectFilter,
      select,
      groups,
      handleGroupFilter,
      selectGroups,
    } = this.props;
    const openArrowMenuClassName = this.state.open ? "open" : "";
    const openContentClassName = this.state.open ? "open-content" : "";

    const openContentGroupClassName = this.state.openGroups ? "open" : "";

    //console.log("search prps", this.props);
    return (
      <div className="search">
        <div className="name">
          <input
            onChange={handleTextFilter}
            className="name__input"
            type="text"
            id="name"
            value={search}
            placeholder="buscador"
          />
          <img className="name__input-lupa" src={lupa} alt="icono lupa" />
        </div>
        <div className={`brands ${openContentClassName}`}>
          {/*<label
            htmlFor="brands"
            className="brands__label"
            onClick={this.toggle}
          >
            marca{" "}
            <img
              className={`brands__back-arrow ${openArrowMenuClassName}`}
              src={arrowIcon}
              alt="icono flecha"
            />
          </label>
          {select.length >= 1 ? (
            <div className="brands__selected-container">
              <p className="brands__selected-items">
                ({select.length}{" "}
                {select.length === 1 ? (
                  <span>Marca selecionada</span>
                ) : (
                  <span>Marcas seleccionadas</span>
                )}
                )
              </p>
              <button
                className="brands__selected-delete-btn"
                onClick={this.deleteButton}
              >
                borrar
              </button>
            </div>
          ) : null}
          <CollapsibleSearch open={this.state.open}>
            {brands.map((brand, index) => {
              return (
                <button
                  key={index}
                  value={brand.ID}
                  onClick={handleSelectFilter}
                  id="selectButton"
                  className="brands__btn"
                >
                  {brand.Brand}
                </button>
              );
            })} */}
          <label
            htmlFor="groups"
            className="groups__label"
            onClick={this.toggleGroups}
          >
            gama
            <img
              className={`groups__back-arrow ${openContentGroupClassName}`}
              src={arrowIcon}
              alt="icono flecha"
            />
          </label>
          {selectGroups.length >= 1 ? (
            <div className="groups__selected-container">
              <p className="groups__selected-items">
                ({selectGroups.length}{" "}
                {selectGroups.length === 1 ? (
                  <span>Selecionada</span>
                ) : (
                  <span>Seleccionadas</span>
                )}{" "}
                )
              </p>
              <button
                className="groups__selected-delete-btn"
                onClick={this.deleteButtonGroups}
              >
                borrar
              </button>
            </div>
          ) : null}
          {groups.map((group, index) => {
            return (
              <CollapsibleGroups open={this.state.openGroups} key={index} style="margin:0">
                <button
                  value={group.ID}
                  onClick={handleGroupFilter}
                  id="selectButton2"
                  className="groups__btn"
                >
                  {group.Name}
                </button>
              </CollapsibleGroups>
            );
          })}
          {/* </CollapsibleSearch> */}
        </div>

        {/* <div className={`brands ${openContentClassName}`}>
          <label
            htmlFor="groups"
            className="groups__label"
            onClick={this.toggle}
          >
            Groups{" "}
            <img
              className={`brands__back-arrow ${openArrowMenuClassName}`}
              src={arrowIcon}
              alt="icono flecha"
            />
          </label>
          {/*           
          {select.length >= 1 ? (
            <div className="brands__selected-container">
              <p className="brands__selected-items">
                ({select.length} Seleccionados)
              </p>
              <button
                className="brands__selected-delete-btn"
                onClick={this.deleteButton}
              >
                borrar
              </button>
            </div>
          ) : null} 

          {groups.map((group, index) => {
            return (
              <CollapsibleSearch open={this.state.open} key={index}>
                <button
                  value={group.ID}
                  onClick={handleGroupFilter}
                  id="selectButton2"
                  className="groups__btn"
                >
                  {group.Name}
                </button>
              </CollapsibleSearch>
            );
          })}
        </div> */}
      </div>
    );
  }
}

export default Search;
