import React from "react";
import { Link } from "react-router-dom";

import "../stylesheets/layout/Header.scss";
import somosFamilia from  "../images/somos_familia.png"

const Header = () => {
  return (
    <div className="header">
      <Link to="/">
        <img
          className="header__logo-san-miguel"
          src={somosFamilia}
          alt="logo Mahou y San Miguel"
        />
      </Link>
    </div>
  );
};

export default Header;
