import React from "react";
import { Radar } from "react-chartjs-2";


import "../stylesheets/layout/Brujula.scss";
import backgroundBrujula from "../images/brujula_cervecistas.svg";
import backgroundBrujulaZN from "../images/brujulas/brujula-zn.svg";
import backgroundBrujulaES from "../images/brujulas/brujula-es.svg";
import backgroundBrujulaEN from "../images/brujulas/brujula-en.svg";
import backgroundBrujulaIT from "../images/brujulas/brujula-it.svg";
import backgroundBrujulaDE from "../images/brujulas/brujula-de.svg";
import backgroundBrujulaPT from "../images/brujulas/brujula-pt.svg";
import backgroundBrujulaFR from "../images/brujulas/brujula-fr.svg";

import paperclip from "../images/paperclip.svg";
import * as jsPDF from "jspdf";

import Spinner from "./Spinner";

let validImgCompass;
let titleCompass;
let nameBeer;


class Brujula extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showSpinner: false
        }
    }

    descargarBrujula(e) {
        e.preventDefault();

        this.setState({
            showSpinner: true
        });

        //preparar imagen fondo brujula
        var imgBrujulaBack = new Image();

        //opciones del canvas (calidad)
        var canvasOptions = {
            format: 'jpeg',
            quality: 1,
            imageSmoothingQuality: "high"
        }

        imgBrujulaBack.onload = function () {
            canvasBrujulaBack.width = 2000
            canvasBrujulaBack.height = 2000
            ctx.imageSmoothingQuality = 'high';
            ctxCanvasBrujula.drawImage(imgBrujulaBack, 0, 0, 2000, 2000)
            ctxCanvasBrujula.drawImage(imgCompass,305, 280, 1400, 1400)
            var dataURL = canvasBrujulaBack.toDataURL(canvasOptions);

            validImgCompass = new Image(dataURL);

            validImgCompass.src = dataURL;
            //var dataURL = canvasBrujulaBack.toDataURL('image/png');
        }

        var canvas = document.createElement('canvas');
        var ctx = canvas.getContext('2d');
        var canvasBrujulaBack = document.createElement('canvas');
        var ctxCanvasBrujula = canvasBrujulaBack.getContext('2d');
        var canvasBrujula = document.getElementsByClassName('chartjs-render-monitor')[0];

        var dataURL = canvasBrujula.toDataURL(canvasOptions);
        var imgCompass = new Image(dataURL);
        imgCompass.src = dataURL;


        switch (this.props.language) {
            case "es":
                imgBrujulaBack.src = backgroundBrujulaES;
                break;
            case "en":
                imgBrujulaBack.src = backgroundBrujulaEN;
                break;
            case "fr":
                imgBrujulaBack.src = backgroundBrujulaFR;
                break;
            case "zn":
                imgBrujulaBack.src = backgroundBrujulaZN;
                break;
            case "pt":
                imgBrujulaBack.src = backgroundBrujulaPT;
                break;
            case "it":
                imgBrujulaBack.src = backgroundBrujulaIT;
                break;
            case "de":
                imgBrujulaBack.src = backgroundBrujulaDE;
                break;
            default:
                imgBrujulaBack.src = backgroundBrujulaEN;
        }



        //le damos tiempo a la funcion para asegurarnos que todo carga
        setTimeout(function () {
            const doc = new jsPDF("p", "px", [350, 350]);

            //BRUJULA
            doc.addImage(validImgCompass, "JPEG", 50, 65, 250, 250);
            doc.setFontSize(22);
            doc.setTextColor("black");
            var width = doc.internal.pageSize.getWidth()
            //doc.text('Centered text', width/2, 20, { align: 'center' })
            doc.text(
                `${nameBeer}`,
                width / 2, 40, { align: 'center' }
            );
            doc.save(`brujula_${nameBeer}.pdf`);

        }, 1000);

        //timeout para ocultar el spinner
        setTimeout(function () {
            this.setState({ showSpinner: false });
        }.bind(this), 1000);

    }


    calcularPuntosBrujula(values) {
        let new_values = new Array();
        let posicionesPuntos = new Array(0, 33, 60, 75, 90, 99);
        values.forEach(e => {
            e = e.replace(',', '.')
            let v = parseFloat(e).toFixed(2);
            let new_value = parseFloat((v * 100) / 5);
            if (v >= 5) {
                v = 5;
                new_value = posicionesPuntos[v];
            } else {
                //tomamos como base la posición sin  y le sumamos la parte proporcional de espacio que queda con el siguiente punto.
                let resto = v - Math.floor(v);
                let intervalo = posicionesPuntos[Math.floor(v) + 1] - posicionesPuntos[Math.floor(v)];
                new_value = posicionesPuntos[Math.floor(v)] + (resto * intervalo);
                //console.log("posicion:",Math.floor(v), ", resto:", resto, ", intervalo:", intervalo);
            }

            new_values.push(new_value);
            //console.log("v:", v,", new_value:", new_value );
        });
        return new_values;
    }



    render() {
        //const segmentActive = this.props.showSpinner;

        titleCompass = this.props.titleCompass;
        nameBeer = this.props.nameBeer;

        //console.log('this.props.puntos', this.props.puntos)
        let brujulaData = this.calcularPuntosBrujula(this.props.puntos)
        //console.log('brujulaData', this.props.language)

        const brujula = {
            width: "400px",
            height: "400px",
            display: "block",
            margin: "0 auto",
            backgroundImage: "",
            backgroundPosition: 'center',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            position: "relative"
        }

        switch (this.props.language) {
            case "es":
                brujula.backgroundImage = `url(${backgroundBrujulaES})`;
                break;
            case "en":
                brujula.backgroundImage = `url(${backgroundBrujulaEN})`;
                break;
            case "de":
                brujula.backgroundImage = `url(${backgroundBrujulaDE})`;
                break;
            case "fr":
                brujula.backgroundImage = `url(${backgroundBrujulaFR})`;
                break;
            case "zn":
                brujula.backgroundImage = `url(${backgroundBrujulaZN})`;
                break;
            case "it":
                brujula.backgroundImage = `url(${backgroundBrujulaIT})`;
                break;
            case "pt":
                brujula.backgroundImage = `url(${backgroundBrujulaPT})`;
                break;
            default:
                brujula.backgroundImage = `url(${backgroundBrujulaEN})`;
                break;
        }



        let radarData = {
            labels: [
                " ",
                " ",
                " ",
                " ",
                " ",
                " ",
                " ",
                " ",
                " "
                /* "Herbal",
                 "Amargor",
                 "Color",
                 "Maltoso",
                 "Licoroso",
                 "Afrutado",
                 "Especial",
                 "Acided",
                 "Citrico"*/
            ],
            datasets: [{
                borderWidth: 5,
                color: "rgba(220, 0, 25,1)",
                backgroundColor: `rgba(225, 255, 255,0)`,
                borderColor: `${this.props.color}`,
                pointBackgroundColor: `${this.props.color}`,
                pointBorderColor: `${this.props.color}`,
                pointHoverBackgroundColor: `${this.props.color}`,
                pointHoverBorderColor: `${this.props.color}`,
                pointBorderWidth: 10,
                data: brujulaData,
                fill: true,
                lineWidth: 12,
            }]
        };

        let options = {
            legend: {
                display: false
            },
            scale: {
                ticks: {
                    display: false,
                    beginAtZero: true,
                    max: 100,
                    min: 0,
                    stepSize: 1,
                    maxTicksLimit: 9,
                    showLabelBackdrop: false,
                    suggestedMax: 100,
                    suggestedMin: 0,
                    backdropColor: "rgba(220, 0, 25,0.1)",
                    drawTicks: false,
                    fill: true


                },
                gridLines: {
                    display: false,
                    color: "rgba(0,0,0,0.1)",
                },
                angleLines: {
                    display: false,
                    color: "rgba(0,0,0,1)",
                },
                pointLabels: {
                    display: true,
                    color: "rgba(0,0,0,1)",
                },
                scaleLabel: {
                    display: false
                },
            },
            responsive: true,
            maintainAspectRatio: false,
            title: {
                display: false,
            },
            legend: {
                display: false,
            },
            tooltips: {
                enabled: false,
            },
            animation: {
                duration: 2000,
            },
        };

        return (
            <div id="newID" style={{ opacity: this.state.showSpinner ? 0.5 : 1 }}>
                <Spinner showSpinner={this.state.showSpinner} />
                <div className="brujula" style={brujula}>
                    <Radar data={radarData} options={options} />
                    {this.props.titlesFilter.map((i, index) => {
                        return (
                            <button className="clipDownload">
                                <img
                                    className="download__icon"
                                    src={paperclip}
                                    alt="icono descarga"
                                    
                                />
                                <a className="download__btn-link" onClick={(e) => this.descargarBrujula(e)}>
                                    {i.Download}
                                </a>
                            </button>
                        );
                    })}
                
                </div>
            </div>
        );
    };
}

export default Brujula;