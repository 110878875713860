import React from "react";
import BeerDetail from "./BeerDetail";
import { Link } from "react-router-dom";
import "../stylesheets/layout/home/BeerList.scss";

let calculatedDate;

class BeerList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    //calculatedDate = new Date(this.props.beerDetail.FechaLanzamiento);
    calculatedDate = new Date(this.props.beers.FechaLanzamiento);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  statusResult() {
    const { beers, showResults } = this.propsprops;

    if (beers.length === 1) {
      return <p>{beers.length} resultado encontrado</p>;
    } else if (beers.length > 1) {
      return <p>{beers.length} resultados encontrados</p>;
    } else if (beers.length) {
      return null;
    }
  }

  render() {
    const { beers, showResults } = this.props;

    return (
      <div className="list">
        <div className="list__result-container">
          <p className="list__result">
            {this.showResults === true ? this.statusResult() : null}
          </p>
        </div>
        <div className="list__container">
          {beers.map((beer, index) => {
            //if(beer.BeersgroupsName !== "Agua"){
            return (
              <Link
                to={`/beer-detail/${beer.ID}`}
                className="beer__link"
                key={index}
              >
                <BeerDetail beer={beer} />
              </Link>
            );}
          /*}*/)}
        </div>
      </div>
    );
  }
}

export default BeerList;
