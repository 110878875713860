export const removeTags = (str) => {
  if (str === null || str === "" || str === undefined) return "";
  else str = str.toString();
  let removeHtmlTags = str.replace(/(<([^>]+)>)/gi, "");
  let removeSpaceAndParagraphs = removeHtmlTags.replace(
    /(&nbsp;|<br>|<br \/>)/g,
    ""
  );
  //console.log("srt", removeSpaceAndParagraphs);
  return removeSpaceAndParagraphs;
};
