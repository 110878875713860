
import React from "react";
import "../stylesheets/layout/beerSheet/Items.scss";


let textData = {/*
  //EscuelaEstilo y Estilo
  EscuelaEstilo: "",
  //materias primas
  Agua: "",
  Malta: "",
  Lupulo: "",
  Levadura: "",
  Otros: "",
  Estilo: "",
  //sabias que
  Adestacar: "",
  //cata
  NotaCataSabor: "",
  NotaCataApariencia: "",
  //comprobacion de si hay maridaje
  MaridajeEntrante: "",
  MaridajeGeneral: "",
  MaridajePostre: "",
  MaridajePrimero: "",
  MaridajeSegundo: "",
  //perfect server general
  PerfectServeGeneral: "",
  //comprobacion de si hay info adicional
  InfoAdicional: "",
  //comprobacion de si hay video
  Video1Link: "",
*/
};

let beerData = {/*
  //datos tecnicos
  Color: "",
  Extracto: "",
  Amargo: "",
  Alcohol: "",
  Potasio: "",
  Magnesio: "",
  Sodio: "",
  Calcio: "",
  //brujula
  BrujulaAcidez: "0",
  BrujulaAfrutado: "0",
  BrujulaAmargor: "0",
  BrujulaCitrico: "0",
  BrujulaColor: "0",
  BrujulaEspecial: "0",
  BrujulaHerbal: "0",
  BrujulaLicoroso: "0",
  BrujulaMaltoso: "0",
  //perfect serve min y max
  PerfectServeTempMax: 0,
  PerfectServeTempMin: 0,*/
}
let esAgua = "False";

class MenuItems extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {

  }
  render() {

    const {
      burguerMenu,
      languages,
      genPDF,
      downloadPDF,
      moreDataDetail,
      titlesFilter,
      beerDetail,
      prizesDetail
    } = this.props;

    if (this.props.beerDetail) {
      if (this.props.beerDetail.esAgua) {
        esAgua = this.props.beerDetail.esAgua;
      } else {
        esAgua = "False";
      }
    } else {
      esAgua = "False";
    }
    //console.log(prizesDetail.length, "PRIZEEEEESSSSS");

    if (this.props.moreDataDetail[0]) {
      textData = this.props.moreDataDetail[0];
      //console.log(textData, "DATAAAAAAAAAAAAAAA");
    }
    if (this.props.beerDetail) {
      beerData = this.props.beerDetail;
      //console.log(beerData, "BEEEEEEEEERRRRR");
    }

    //contador para indexado numérico de secciones
    let indexCount = 1;

    let scrollingThis = (e) => {
      //props.handleCollapsibleMenu(e);

      let htmlElement;
      let getButton;
      let headerHeight = 104;
      let item = e.target.textContent;


      this.props.titlesFilter.map((i, index) => {
        switch (item) {
          case i.Description:
            htmlElement = document.querySelector(".title__name");
            break;
          case i.TechnicalData:
            htmlElement = document.querySelector(".technical__title");
            htmlElement != null
              ? (getButton = htmlElement.closest("button"))
              : (getButton = "");
            break;
          case i.Minerals:
            htmlElement = document.querySelector(".technical__title");
            htmlElement != null
              ? (getButton = htmlElement.closest("button"))
              : (getButton = "");
            break;
          case i.Materials:
            htmlElement = document.querySelector(".materials__title");
            htmlElement != null
              ? (getButton = htmlElement.closest("button"))
              : (getButton = "");
            break;
          case i.Composition:
            htmlElement = document.querySelector(".materials__title");
            htmlElement != null
              ? (getButton = htmlElement.closest("button"))
              : (getButton = "");
            break;
          case i.Compass:
            htmlElement = document.querySelector(".compass__title");
            htmlElement != null
              ? (getButton = htmlElement.closest("button"))
              : (getButton = "");
            break;
          case i.Style:
            htmlElement = document.querySelector(".styleBeer__title");
            htmlElement != null
              ? (getButton = htmlElement.closest("button"))
              : (getButton = "");
            break;
          case i.Highlight:
            htmlElement = document.querySelector(".highlight__title");
            htmlElement != null
              ? (getButton = htmlElement.closest("button"))
              : (getButton = "");
            break;
          case i.Tasting:
            htmlElement = document.querySelector(".tasting__title");
            htmlElement != null
              ? (getButton = htmlElement.closest("button"))
              : (getButton = "");
            break;
          case i.Pairing:
            htmlElement = document.querySelector(".pairing__title");
            htmlElement != null
              ? (getButton = htmlElement.closest("button"))
              : (getButton = "");
            break;
          case i.Perfect:
            htmlElement = document.querySelector(".perfect__title");
            htmlElement != null
              ? (getButton = htmlElement.closest("button"))
              : (getButton = "");
            break;
          case i.Awards:
            htmlElement = document.querySelector(".awards__title");
            htmlElement != null
              ? (getButton = htmlElement.closest("button"))
              : (getButton = "");
            break;
          case i.Additional:
            htmlElement = document.querySelector(".additional__title");
            htmlElement != null
              ? (getButton = htmlElement.closest("button"))
              : (getButton = "");
            break;
          case i.Videos:
            htmlElement = document.querySelector(".videos__title");
            htmlElement != null
              ? (getButton = htmlElement.closest("button"))
              : (getButton = "");
            break;
        }
      });
      if (htmlElement != null) {
        htmlElement.scrollIntoView();
        let scrolledY = window.scrollY;
        if (scrolledY) {
          window.scroll(0, scrolledY - headerHeight);
        }
        if (getButton != null) {
          //getButton.click();
        }
      }
    };

    return (
      <nav>
        {this.props.titlesFilter.map((i, index) => {
          return (
            //listado
            <ol className={this.props.burguerMenu === true ? "items-mobile" : "items"}>
              {/* DESCRIPCION */}
              <li className="items__item">
                <div className="items__content" onClick={scrollingThis}>
                  <p className="items__text">{i.Description}</p>
                  <span className="items__number">{indexCount < 9 ? "0" + indexCount + "." : ++indexCount + "."}</span>

                </div>
              </li>
              {/* DATOS TECNICOS */}
              {beerData.length != 0 &&
                ((beerData.Color != "0" && beerData.Color != undefined) ||
                  (beerData.Extracto != "0" && beerData.Extracto != undefined) ||
                  (beerData.Amargo != "0" && beerData.Amargo != undefined) ||
                  (beerData.Alcohol != "0" && beerData.Alcohol != undefined) ||
                  (beerData.Potasio != "0" && beerData.Potasio != undefined) ||
                  (beerData.Magnesio != "0" && beerData.Magnesio != undefined) ||
                  (beerData.Sodio != "0" && beerData.Sodio != undefined) ||
                  (beerData.Calcio != "0" && beerData.Calcio != undefined)
                ) ?
                <li className="items__item">
                  <div className="items__content" onClick={scrollingThis}>

                    {console.log(esAgua, "minerals")}
                    {esAgua == "False" ?
                      <p className="items__text">{i.TechnicalData}</p>
                      :
                      <p className="items__text">{i.Minerals}</p>
                    }
                    <span className="items__number">{indexCount < 9 ? "0" + (++indexCount) + "." : ++indexCount + "."}</span>
                  </div>
                </li>
                :
                ""
              }
              {/* BRUJULA */}


              {beerData.length != 0 &&
                ((beerData.BrujulaAcidez != "0" && beerData.BrujulaAcidez != undefined) ||
                  (beerData.BrujulaAfrutado != "0" && beerData.BrujulaAfrutado != undefined) ||
                  (beerData.BrujulaAmargor != "0" && beerData.BrujulaAmargor != undefined) ||
                  (beerData.BrujulaCitrico != "0" && beerData.BrujulaCitrico != undefined) ||
                  (beerData.BrujulaColor != "0" && beerData.BrujulaColor != undefined) ||
                  (beerData.BrujulaEspecial != "0" && beerData.BrujulaEspecial != undefined) ||
                  (beerData.BrujulaHerbal != "0" && beerData.BrujulaHerbal != undefined) ||
                  (beerData.BrujulaLicoroso != "0" && beerData.BrujulaLicoroso != undefined) ||
                  (beerData.BrujulaMaltoso != "0" && beerData.BrujulaMaltoso != undefined)
                ) ?
                <li className="items__item">
                  <div className="items__content" onClick={scrollingThis}>
                    <p className="items__text">{i.Compass}</p>
                    <span className="items__number">{indexCount < 9 ? "0" + (++indexCount) + "." : ++indexCount + "."}</span>
                  </div>
                </li>
                :
                ""
              }
              {/* ESTILO / ESCUELA */}
              {textData.length != 0 &&
                (
                  (textData.EscuelaEstilo != undefined && textData.EscuelaEstilo != "")
                  ||
                  (textData.Estilo != undefined && textData.Estilo != "")
                ) ?
                <li className="items__item">
                  <div className="items__content" onClick={scrollingThis}>
                    <p className="items__text">{i.Style}</p>
                    <span className="items__number">{indexCount < 9 ? "0" + (++indexCount) + "." : ++indexCount + "."}</span>
                  </div>
                </li>
                :
                ""
              }
              {/* MATERIAS PRIMAS */}
              {textData.length != 0 &&
                ((textData.Agua != "" && textData.Agua != undefined) ||
                  (textData.Malta != "" && textData.Malta != undefined) ||
                  (textData.Lupulo != "" && textData.Lupulo != undefined) ||
                  (textData.Levadura != "" && textData.Levadura != undefined) ||
                  (textData.Otros != "" && textData.Otros != undefined)
                ) ?
                <li className="items__item">
                  <div className="items__content" onClick={scrollingThis}>
                    {console.log(esAgua, "composicion")}
                    {esAgua == "False" ?
                      <p className="items__text">{i.Materials}</p>
                      :
                      <p className="items__text">{i.Composition}</p>
                    }
                    <span className="items__number">{indexCount < 9 ? "0" + (++indexCount) + "." : ++indexCount + "."}</span>
                  </div>
                </li>
                :
                ""
              }
              {/* SABIAS QUE */}
              {textData.length != 0 && textData.Adestacar != undefined && textData.Adestacar != "" ?
                <li className="items__item">
                  <div className="items__content" onClick={scrollingThis}>
                    <p className="items__text">{i.Highlight}</p>
                    <span className="items__number">{indexCount < 9 ? "0" + (++indexCount) + "." : ++indexCount + "."}</span>
                  </div>
                </li>
                :
                ""
              }
              {/* CATA */}
              {textData.length != 0 &&
                (
                  (textData.NotaCataSabor != undefined && textData.NotaCataSabor != "")
                  ||
                  (textData.NotaCataApariencia != undefined && textData.NotaCataApariencia != "")
                ) ?
                <li className="items__item">
                  <div className="items__content" onClick={scrollingThis}>
                    <p className="items__text">{i.Tasting}</p>
                    <span className="items__number">{indexCount < 9 ? "0" + (++indexCount) + "." : ++indexCount + "."}</span>
                  </div>
                </li>
                :
                ""
              }
              {/* MARIDAJE */}
              {textData.length != 0 &&
                ((textData.MaridajeEntrante != "" && textData.MaridajeEntrante != undefined) ||
                  (textData.MaridajeGeneral != "" && textData.MaridajeGeneral != undefined) ||
                  (textData.MaridajePostre != "" && textData.MaridajePostre != undefined) ||
                  (textData.MaridajePrimero != "" && textData.MaridajePrimero != undefined) ||
                  (textData.MaridajeSegundo != "" && textData.MaridajeSegundo != undefined)
                ) ?
                <li className="items__item">
                  <div className="items__content" onClick={scrollingThis}>
                    <p className="items__text">{i.Pairing}</p>
                    <span className="items__number">{indexCount < 9 ? "0" + (++indexCount) + "." : ++indexCount + "."}</span>
                  </div>
                </li>
                :
                ""
              }
              {/* PERFECT SERVE */}
              {textData.length != 0 &&
                (
                  textData.PerfectServeGeneral != "" && textData.PerfectServeGeneral != undefined ||
                  beerData.PerfectServeTempMax != 0 && beerData.PerfectServeTempMax != undefined ||
                  beerData.PerfectServeTempMin != 0 && beerData.PerfectServeTempMin != undefined
                )
                ?
                <li className="items__item">
                  <div className="items__content" onClick={scrollingThis}>
                    <p className="items__text">{i.Perfect}</p>
                    <span className="items__number">{indexCount < 9 ? "0" + (++indexCount) + "." : ++indexCount + "."}</span>
                  </div>
                </li>
                :
                ""
              }
              {/* PREMIOS */}
              {/*TODO*/}
              {prizesDetail != 0 ?
                <li className="items__item">
                  <div className="items__content" onClick={scrollingThis}>
                    <p className="items__text">{i.Awards}</p>
                    <span className="items__number">{indexCount < 9 ? "0" + (++indexCount) + "." : ++indexCount + "."}</span>
                  </div>
                </li>
                :
                ""
              }
              {/* INFO ADICIONAL */}
              {textData.length != 0 && textData.InfoAdicional != undefined && textData.InfoAdicional != "" ?
                //this.props.moreDataDetail[0]
                <li className="items__item">
                  <div className="items__content" onClick={scrollingThis}>
                    <p className="items__text">{i.Additional}</p>
                    <span className="items__number">{indexCount < 9 ? "0" + (++indexCount) + "." : ++indexCount + "."}</span>
                  </div>
                </li>
                :
                ""
              }
              {/* VIDEOS */}
              {textData.length != 0 && textData.Video1Link != undefined && textData.Video1Link != "" ?
                <li className="items__item">
                  <div className="items__content" onClick={scrollingThis}>
                    <p className="items__text">{i.Videos}</p>
                    <span className="items__number">{indexCount < 9 ? "0" + (++indexCount) + "." : ++indexCount + "."}</span>
                  </div>
                </li>
                :
                ""
              }
            </ol>
          );
        })}
      </nav>
    );
  }
}

export default MenuItems;
