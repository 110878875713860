import React from "react";
import ReactDOM from "react-dom";
import "react-app-polyfill/ie11";
import "core-js/stable";
import "core-js/features/array/find";
import "core-js/features/array/includes";
import "core-js/features/number/is-nan";
import { detect } from "detect-browser";
import { BrowserModal } from "./components/BrowserModal";
import App from "./App";
import { HashRouter } from "react-router-dom";
import "./index.css";

const browser = detect();

switch (browser && browser.name) {
  case "chrome":
  case "firefox":
    ReactDOM.render(
      <HashRouter>
        <App />
      </HashRouter>,
      document.getElementById("root")
    );
    break;
  case "edge":
    ReactDOM.render(
      <HashRouter>
        <App />
      </HashRouter>,
      document.getElementById("root")
    );
    break;
  case "explorer":
  case "ie":
    ReactDOM.render(<BrowserModal />, document.getElementById("root"));
    break;
  default:
    ReactDOM.render(<BrowserModal />, document.getElementById("root"));
}
