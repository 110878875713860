import React from "react";
import "../stylesheets/layout/beerSheet/StyleBeer.scss";

class Collapsible extends React.Component {
  constructor(props) {
    super(props);
    // this.myRef = React.createRef();
    this.state = {
      open: true,
    };
    this.toggle = this.toggle.bind(this);
  }
  toggle(e) {
    //const value = e.currentTarget.value;
    // console.log("value", value);
    // console.log("state", this.state.open);
    this.setState((prevState) => {
      //console.log("prevState.open", prevState.open);
      return {
        open: !prevState.open,
        // currentcategory: value,
      };
    });
    //this.setState({ currentcategory: value });
  }
  render() {
    //console.log("this.props.menuitemss", this.props.children);
    //"static/media/icono-flecha-roja.361cf5a9.svg"
    const openClassName = this.props.open ? "" : "hidden";
    const flecha = require('../icons/icono-flecha-roja.svg'); 
    return (
      <section>
        <button
          className="styleBeer__title-content"
          onClick={this.toggle}
          value="technical"
        >
          <h2 className={this.props.className}>{this.props.title}</h2>
          <img
            className={
              this.state.open
                ? "styleBeer__red-arrow "
                : "styleBeer__red-arrow openArrow"
            }
            src={flecha}
            alt="icono flecha roja"
          />
        </button>
        <div
          className={this.state.open ? "collapsable " : "collapsable hidden"}
        >
          {this.props.children}
        </div>
      </section>
    );
  }
}

export default Collapsible;
