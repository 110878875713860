import React from "react";
import { Route, Switch } from "react-router-dom";
import Header from "./components/Header";
import Home from "./components/Home";
import BeerData from "./components/BeerData";

import {
  getBeersFromApi,
  getLanguagesFromApi,
  getPhotoTypesFromApi,
  getBrandsFromApi,
  getLanguagesTitlesFromData,
  getBeersGroupsFromData,
} from "./services/fetch";
import "./App.css";

var parseString = require("xml2js").parseString;
let beersApi;
let languagesApi;
let photoTypesApi;
let brandsApi;
let groupsApi;

let selectInput;
let htmlElement;
let htmlElementBtn;
let selectInputGroup;

class App extends React.Component {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();

    this.state = {
      beers: [],
      languages: [],
      photoTypes: [],
      brands: [],
      search: "",
      select: [],
      selectGroups: [],
      langTitles: [],
      groups: [],
      showResults: false,
    };
    this.handleTextFilter = this.handleTextFilter.bind(this);
    this.handleSelectFilter = this.handleSelectFilter.bind(this);
    this.deleteSearch = this.deleteSearch.bind(this);
    this.handleGroupFilter = this.handleGroupFilter.bind(this);
    this.deleteSearchGroups = this.deleteSearchGroups.bind(this);
  }
  componentDidMount() {
    getBeersFromApi().then((data) => {
      
      parseString(data, function (err, result) {
        beersApi = result;
        
      });
      const beersData = beersApi.Beers.items.map((i) => i.item);
      
      console.log(this.props.match);
      const beersMap = beersData.map((i) => i.map((i) => i.$));
      const beersClear = beersMap.flat();

      this.setState({
        beers: beersClear,
      });
    });

    getLanguagesFromApi().then((lang) => {
      parseString(lang, function (err, res) {
        languagesApi = res;
      });
      const languagesData = languagesApi.Languages.items.map((i) => i.item);
      const languagesMap = languagesData.map((i) => i.map((i) => i.$));
      const languagesClear = languagesMap.flat();
      this.setState({
        languages: languagesClear,
      });
    });

    getPhotoTypesFromApi().then((photoTypes) => {
      parseString(photoTypes, function (err, res) {
        photoTypesApi = res;
      });
//console.log("photoTypesApi", photoTypesApi);
      const photoTypesData = photoTypesApi.PhotoTypes.items.map((i) => i.item);
      const photoTypesMap = photoTypesData.map((i) => i.map((i) => i.$));
      const photoTypesClear = photoTypesMap.flat();
      this.setState({
        photoTypes: photoTypesClear,
      });
    });

    getBrandsFromApi().then((brands) => {
      parseString(brands, function (err, res) {
        brandsApi = res;
      });
      const brandsData = brandsApi.Brands.items.map((i) => i.item);
      const brandsMap = brandsData.map((i) => i.map((i) => i.$));
      const brandsClear = brandsMap.flat();
      this.setState({
        brands: brandsClear,
      });
    });
    getBeersGroupsFromData().then((groups) => {
      parseString(groups, function (err, res) {
        groupsApi = res;
      });
      const groupsData = groupsApi.beersGroups.items.map((i) => i.item);
      const groupsMap = groupsData.map((i) => i.map((i) => i.$));
      const groupsClear = groupsMap.flat();
      this.setState({
        groups: groupsClear,
      });
    });

    getLanguagesTitlesFromData().then((titleLang) => {
      this.setState({
        langTitles: titleLang,
      });
    });
  }
  handleTextFilter(event) {
    const textInput = event.currentTarget.value;
    this.setState({
      search: textInput,
      showResults: true,
    });
  }
  handleSelectFilter(ev) {
    htmlElement = ev.target;
    selectInput = ev.currentTarget.value;
    this.setState((prevState) => {
      return {
        select: prevState.select.find((i) => i === selectInput)
          ? prevState.select.filter((i) => i !== selectInput)
          : prevState.select.concat(selectInput),
        showResults: true,
      };
    });
    this.state.select.find((i) => i === selectInput)
      ? (htmlElement.className = "brands__btn")
      : (htmlElement.className = "brands__btn red");
  }
  handleGroupFilter(ev) {
    htmlElementBtn = ev.target;
    selectInputGroup = ev.currentTarget.value;
    this.setState((prevState) => {
      return {
        selectGroups: prevState.selectGroups.find((i) => i === selectInputGroup)
          ? prevState.selectGroups.filter((i) => i !== selectInputGroup)
          : prevState.selectGroups.concat(selectInputGroup),
        showResults: true,
      };
    });
    this.state.selectGroups.find((i) => i === selectInputGroup)
      ? (htmlElementBtn.className = "groups__btn")
      : (htmlElementBtn.className = "groups__btn red");
  }
  deleteSearch(selectButton) {
    this.setState({
      select: [],
    });
    for (let i = 0; i < selectButton.length; i++) {
      selectButton[i].className = "brands__btn";
    }
  }
  deleteSearchGroups(groupsButton) {
    this.setState({
      selectGroups: [],
    });
    for (let i = 0; i < groupsButton.length; i++) {
      groupsButton[i].className = "groups__btn";
    }
  }

  render() {
    // console.log("APP state", this.state.beers);
    // console.log("APP state", this.state.groups);
    
    const {
      beers,
      search,
      languages,
      brands,
      select,
      langTitles,
      showResults,
      groups,
      selectGroups,
    } = this.state;

    let filter = beers;

    if (select.length > 0 && selectGroups.length == 0) {
      filter = beers
        .filter((item) =>
          item.Name.toUpperCase().includes(search.toUpperCase())
        )
        .filter((i) => select.includes(i.BrandId));
    }
    if (selectGroups.length > 0 && select.length == 0) {
      filter = beers
        .filter((item) =>
          item.Name.toUpperCase().includes(search.toUpperCase())
        )
        .filter((el) => selectGroups.includes(el.GroupId));
    }
    if (selectGroups.length > 0 && select.length > 0) {
      filter = beers
        .filter((item) =>
          item.Name.toUpperCase().includes(search.toUpperCase())
        )
        .filter((el) => selectGroups.includes(el.GroupId))
        .filter((i) => select.includes(i.BrandId));
    }
    if (selectGroups.length == 0 && select.length == 0) {
      filter = beers.filter((item) =>
        item.Name.toUpperCase().includes(search.toUpperCase())
      );
    }

    return (
      <div>
        <Header />
        <Switch>
          <Route
            exact
            path="/"
            render={() => {
              return (
                <Home
                  beers={filter}
                  handleTextFilter={this.handleTextFilter}
                  search={search}
                  brands={brands}
                  handleSelectFilter={this.handleSelectFilter}
                  select={select}
                  deleteSearch={this.deleteSearch}
                  showResults={showResults}
                  groups={groups}
                  handleGroupFilter={this.handleGroupFilter}
                  selectGroups={selectGroups}
                  deleteSearchGroups={this.deleteSearchGroups}
                />
              );
            }}
          />
          <Route
            path="/beer-detail/:beerID"
            render={(routerProps) => {
              return (
                <div id="pdf-print">
                  <BeerData
                    routerProps={routerProps}
                    beers={beers}
                    languages={languages}
                    brands={brands}
                    langTitles={langTitles}
                  />
                </div>
              );
            }}
          />
        </Switch>
      </div>
    );
  }
}

export default App;
