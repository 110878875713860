import React from "react";
import "../stylesheets/layout/beerSheet/StyleBeer.scss";

class CollapsibleGroups extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const openClassName = this.props.open ? "" : "hidden";
    return (
      <section>
        <div className={`collapsable ${openClassName}`}>
          {this.props.children}
        </div>
      </section>
    );
  }
}

export default CollapsibleGroups;
