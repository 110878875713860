import React from "react";
import noImage from "../images/noImagew.png";

import "../stylesheets/layout/home/BeerDetail.scss";

const BeerDetail = (props) => {
  const { beer } = props;

  var rutaApi = window.ENV.urlBack;
  const imageFromApi = `${rutaApi}/blob.ashx?beersProductImageContent=t|${beer.ID}&_nocrop`;

  let newBeerDate = new Date(beer.FechaLanzamiento);
  let newTodayDate = new Date();
  let calculateMonths = () => {
    let months =
      newTodayDate.getMonth() -
      newBeerDate.getMonth() +
      12 * (newTodayDate.getFullYear() - newBeerDate.getFullYear());
    if (newTodayDate.getDate() < newBeerDate.getDate()) {
      months--;
    }
    return months;
  };

  function addDefaultSrc(ev) {
    ev.target.src = "";
  }

  let resta = newTodayDate - newBeerDate;
  return (
    <div className="detail">
      {calculateMonths() < 2 ? (
        <p className="detail__new-beer">Nuevo</p>
      ) : (
        <p className="detail__new-beer-hidden"></p>
      )}
      <img
        className="detail__img"
        onError={addDefaultSrc}
        src={imageFromApi}
        alt="imagen cerveza"
      />
      <h3 className="detail__title">{beer.Name}</h3>
      {/* {"lanzamiento:"} {newBeerDate.toLocaleDateString()}
      <p>Meses transcurridos: {calculateMonths()}</p> */}
    </div>
  );
};

export default BeerDetail;
