const url = "./services/data.json";
const languajesTitles = "./services/languagesTitles.json";

export function getDataFromServer() {
  return fetch(url).then((response) => response.json());
}

var urlBack = window.ENV.urlBack;
var headers = new Headers();

console.log("incluyendo credenciales basicas ",btoa("cultura:cultura"), window.ENV.urlBack);

if (urlBack.includes("enpruebas.net")){
  console.log("incluyendo credenciales basicas ",btoa("cultura:cultura") );
  //headers.set('Authorization', 'Basic ' + btoa("cultura:cultura") + '')
}


var apiBeers = `${urlBack}/appservices/Beers?EstadoId=3&_pageSize=10000`;
var apiLanguages = `${urlBack}/appservices/Languages`;
var apiBrands = `${urlBack}/appservices/Brands`;
var apiPhotoTypes = `${urlBack}/appservices/PhotoTypes`;
var apiBeersGroups = `${urlBack}/appservices/beersGroups`;



var fetchOptions = {method:'GET',credentials:'include',headers: headers};


export function getFetchOptions(){
  return fetchOptions;
}

export function getLanguagesFromApi() {
  return fetch(apiLanguages, fetchOptions)
    .then((response) => response.text())
    .catch((err) => console.log("error", err));
}

export function getBeersFromApi() {
  return fetch(apiBeers, fetchOptions)
    .then((response) => response.text())
    .catch((err) => console.log("error", err));
}

export function getBrandsFromApi() {
  return fetch(apiBrands, fetchOptions)
    .then((response) => response.text())
    .catch((err) => console.log("error", err));
}

export function getPhotoTypesFromApi() {
  return fetch(apiPhotoTypes, fetchOptions)
    .then((response) => response.text())
    .catch((err) => console.log("error", err));
}

export function getLanguagesTitlesFromData() {
  return fetch(languajesTitles, fetchOptions)
    .then((res) => res.json())
    .catch((err) => console.log("error", err));
}

export function getBeersGroupsFromData() {

  return fetch(apiBeersGroups, fetchOptions)
    .then((res) => res.text())
    .catch((err) => console.log("error", err));
}

// if (process.env.NODE_ENV === 'development') {
//    var urlBack = "en pruebas .net";
// } else {
//    urlBack = "urldeproducion.com"
// }
