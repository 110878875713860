import React from "react";
import browsersImage from "../images/browsers.jpg";

export const BrowserModal = () => {
  return (
    <div className="browserModal">
      <h1>Tu navegador no es compatible</h1>
      <label>Por favor, usa uno de los siguientes:</label>
      <img src={browsersImage} alt="supported-browsers"></img>
    </div>
  );
};
