import React from "react";
import SectionIntro from "./SectionIntro";
import Search from "./Search";
import BeerList from "./BeerList";

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      beers,
      handleTextFilter,
      search,
      brands,
      handleSelectFilter,
      handleGroupFilter,
      select,
      deleteSearch,
      showResults,
      groups,
      deleteSearchGroups,
      selectGroups,
    } = this.props;
    console.log(this.props.showResults);

    return (
      <div className="App">
        <SectionIntro />
        <Search
          handleTextFilter={handleTextFilter}
          search={search}
          brands={brands}
          handleSelectFilter={handleSelectFilter}
          select={select}
          deleteSearch={deleteSearch}
          groups={groups}
          handleGroupFilter={handleGroupFilter}
          selectGroups={selectGroups}
          deleteSearchGroups={deleteSearchGroups}
        />
        <BeerList beers={beers} showResults={showResults} />
      </div>
    );
  }
}

export default Home;
