import React from "react";
import "../stylesheets/layout/home/SectionIntro.scss";
import mLogo from "../images/logo-m-mahou.svg";

const SectionIntro = () => {
  return (
    <div className="section">
      {/* <img src={mLogo} alt="logo Mahou" className="section__logo-img" /> */}
      <h2 className="section__title"> Nuestras marcas </h2>
      <div className="section__text">
        Utiliza esta herramienta para conocer los productos de la familia Mahou
        San Miguel.
      </div>
    </div>
  );
};

export default SectionIntro;
