import React from "react";
import CollapsibleSearch from "./CollapsibleSearch";
import "../stylesheets/layout/beerSheet/Languages.scss";
import menuArrow from "../icons/icono-flecha.svg";

class MenuLanguages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    this.toggle = this.toggle.bind(this);
  }
  toggle() {
    this.setState((prevState) => {
      return {
        open: !prevState.open,
      };
    });
  }
  render() {
    //console.log("props languages", this.props.languages);
    const openArrowClassName = this.state.open ? "open-arrow" : "";
    const bgMenuOpenClassName = this.state.open ? "open-background" : "";
    const { changeLanguage, languages, moreDataDetail } = this.props;

    let arraymove = function (arr, fromIndex, toIndex) {
      var element = arr[fromIndex];
      arr.splice(fromIndex, 1);
      arr.splice(toIndex, 0, element);
    }; //le metes el array y la posicion del elemento y hasta donde quieres que acabe ese elemento.

    moreDataDetail.forEach((item, i) => {
      if (item.Language === "es") {
        moreDataDetail.splice(i, 1);
        moreDataDetail.unshift(item);
      } else if (item.Language === "en") {
        arraymove(moreDataDetail, i, 1);
      } else if (item.Language === "de") {
        arraymove(moreDataDetail, i, 2);
      } else if (item.Language === "zh") {
        moreDataDetail.push(moreDataDetail.splice(i, 1)[0]);
      }
    });
    return (
      <nav>
        {this.props.burguerMenu === true ? (
          <ul className="languagesMobile">
            {moreDataDetail.map((lang, index) => {
              return (
                <div key={index}>
                  {lang.Language === "es" ? (
                    <li className="languagesMobile__item" onClick={this.toggle}>
                      <button
                        className="languagesMobile__btn"
                        id={lang.Language}
                        onClick={changeLanguage}
                      >
                        <img
                          className={`languagesMobile__arrow ${openArrowClassName}`}
                          src={menuArrow}
                          alt="icono flecha desplegar idiomas"
                        />
                        {lang.Language}
                      </button>
                    </li>
                  ) : (
                    <CollapsibleSearch open={this.state.open} key={index}>
                      <li
                        className={`languagesMobile__item ${bgMenuOpenClassName}`}
                      >
                        <button
                          className="languagesMobile__btn"
                          id={lang.Language}
                          onClick={changeLanguage}
                        >
                          {lang.Language}
                        </button>
                      </li>
                    </CollapsibleSearch>
                  )}
                </div>
              );
            })}
          </ul>
        ) : (
          <ul className="languages">
            {moreDataDetail.map((lang, index) => {
              return (
                <li className="languages__item" key={index}>
                  <button
                    className="languages__btn"
                    id={lang.Language}
                    onClick={changeLanguage}
                  >
                    {lang.Language}
                  </button>
                </li>
              );
            })}
          </ul>
        )}
      </nav>
    );
  }
}
export default MenuLanguages;
