import React, { createRef } from "react";
import Header from "./Header";
import Menu from "./Menu";
import Description from "./Description";
import DescriptionWater from "./DescriptionWater";
import { Link } from "react-router-dom";
import "../stylesheets/layout/beerSheet/BeerSheet.scss";
import { getBeersFromApi } from "../services/fetch";

import {
  getFetchOptions,
} from "../services/fetch";

var parseString = require("xml2js").parseString;
var urlBack = window.ENV.urlBack;

let translationsApi;
let photosApi;
let prizesApi;
let segmentsApi;
let SegmentId;
let beerID;
let detail;
let beerDetail;
let moreData;
let segmento;
let beersApi;
let esAgua;
let beerDetailSegment = {
  SegmentId: 1
};



class BeerData extends React.Component {
  constructor(props) {
    super(props);
    //this.child = React.createRef();
    this.state = {
      beers: [],
      langSelect: "es",
      showSpinner: false,
      menuItems: "",
      moreData: [],
      photos: [],
      prizes: [],
      segmentId: 1,
      segmentName: "",
      segmentColor: [],
      segmentActive: false,
      segment: [],

    };
    this.toggleMenuBtn = this.toggleMenuBtn.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.changeLanguage = this.changeLanguage.bind(this);
    this.downloadPDF = this.downloadPDF.bind(this);
    this.handleCollapsibleMenu = this.handleCollapsibleMenu.bind(this);

    const { routerProps, beers } = this.props;
    //console.log(beers); //saca array vacio

    beerID = routerProps.match.params.beerID;

    if (this.props.beers.length > 0) {
      detail = this.props.beers.filter((item) => item.ID === beerID);
      beerDetail = detail[0];
      console.log(detail);

    } else {
      getBeersFromApi().then((data) => {
        parseString(data, function (err, result) {
          beersApi = result;
        });
        const beersData = beersApi.Beers.items.map((i) => i.item);
        const beersMap = beersData.map((i) => i.map((i) => i.$));
        const beersClear = beersMap.flat();
        this.setState({
          beers: beersClear,
        });
      });
      detail = this.state.beers.filter((item) => item.ID === beerID);
      beerDetail = detail[0];
      console.log(detail);
    }

  }


  componentDidMount() {

    if (beerID != undefined) {
      this.fetchTranslatesFromApi();
      this.fetchPhotosFromApi();
      this.fetchPrizesFromApi();
      this.fetchSegments();
    }
  }


  fetchSegments() {
    var apiSegments = `${urlBack}/appservices/BeersSegments`;
    fetch(apiSegments, getFetchOptions())
      .then((response) => response.text())
      .catch((err) => console.log("error", err))
      .then((segments) => {
        parseString(segments, function (err, res) {
          segmentsApi = res;
        });
        moreData = segmentsApi.BeersSegments.items.map((i) => i.item);
        if (
          moreData[0] === undefined ||
          moreData[0] === "" ||
          moreData === null
        ) {
          this.setState({
            segmentColor: "#BE3A34",
          });
        } else {
          const moreDataMap = moreData.map((i) => i.map((i) => i.$));
          const moreDataClear = moreDataMap[0].filter((item) => item.ID == beerDetailSegment.SegmentId);
          //console.log("MOREDATE >>>> ", moreDataMap, this.state.SegmentId, "<<<<  MOREDATA");
          //console.log(moreDataClear);


          this.setState({
            segmentColor: moreDataClear[0] ? moreDataClear[0].Color : '',
            segmentActive: moreDataClear[0] ? moreDataClear[0].Active : '',
            segmentName: moreDataClear[0] ? moreDataClear[0].Name : '',
          });
        }
      });
  }

  fetchTranslatesFromApi() {
    var apiTranslations = `${urlBack}/appservices/BeersTranslations?BeerID=${beerID}`;

    fetch(apiTranslations, getFetchOptions())
      .then((response) => response.text())
      .catch((err) => console.log("error", err))
      .then((tranlations) => {

        parseString(tranlations, function (err, res) {
          translationsApi = res;
        });
        moreData = translationsApi.BeersTranslations.items.map((i) => i.item);
        if (
          moreData[0] === undefined ||
          moreData[0] === "" ||
          moreData === null
        ) {
          this.setState({
            moreData: [],
          });
        } else {
          const moreDataMap = moreData.map((i) => i.map((i) => i.$));
          const moreDataClear = moreDataMap.flat();
          this.setState({
            moreData: moreDataClear,
          });
        }
      });
  }
  fetchPhotosFromApi() {
    var apiPhotos = `${urlBack}/appservices/BeersPhotos?BeerID=${beerID}`;

    fetch(apiPhotos, getFetchOptions())
      .then((response) => response.text())
      .catch((err) => console.log("error", err))
      .then((photos) => {
        parseString(photos, function (err, res) {
          photosApi = res;
        });
        const photosData = photosApi.BeersPhotos.items.map((i) => i.item);
        if (
          photosData[0] === undefined ||
          photosData[0] === "" ||
          photosData === null
        ) {
          this.setState({
            photos: [],
          });
        } else {
          const photosMap = photosData.map((i) => i.map((i) => i.$));
          const photosClear = photosMap.flat();
          this.setState({
            photos: photosClear,
          });
        }
      });
  }
  fetchPrizesFromApi() {
    var apiPrizes = `${urlBack}/appservices/Prizes?BeerID=${beerID}`;

    fetch(apiPrizes, getFetchOptions())
      .then((response) => response.text())
      .catch((err) => console.log("error", err))
      .then((prizes) => {
        parseString(prizes, function (err, res) {
          prizesApi = res;
        });
        const prizesData = prizesApi.Prizes.items.map((i) => i.item);
        if (
          prizesData[0] === undefined ||
          prizesData[0] === "" ||
          prizesData === null
        ) {
          this.setState({
            prizes: [],
          });
        } else {
          const prizesMap = prizesData.map((i) => i.map((i) => i.$));
          const prizesClear = prizesMap.flat();
          this.setState({
            prizes: prizesClear,
          });
        }
      });
  }

  toggleMenuBtn() {
    this.setState((prevState) => {
      return {
        burguerMenu: !prevState.burguerMenu,
      };
    });
  }
  closeMenu() {
    this.setState({
      burguerMenu: false,
    });
  }
  changeLanguage(ev) {
    const idLang = ev.currentTarget.id;
    const mapLanguages = this.props.languages.map((i) => i.ID);
    const langDetail = mapLanguages.filter((item) => item === idLang);
    this.setState({
      langSelect: langDetail[0],
    });
    console.log("langSelect state", this.state.langSelect);
  }

  downloadPDF() {
    this.setState({ showSpinner: true }, () => {
      setTimeout(() => {
        this.handleClick();
      }, 500);
    });
  }

  handleClick = () => {
    const langSel = this.state.langSelect;
    const beerTranslate = this.state.moreData;
    const photos = this.state.photos;
    const prizes = this.state.prizes;
    const segmentActive = this.state.segmentActive;

    //console.log(esAgua);
    
    if (esAgua == "False" ) {
      import("./generatePDF")
        .then(({ imageExists }) => {
          imageExists(this.props, langSel, beerTranslate, photos, prizes, segmentActive);

          setTimeout(() => {
            this.setState({ showSpinner: false });
          }, 12000);

        })
        .catch((err) => {
          // Handle failure
          console.log("err", err);
        });
    } else {
      import("./generateWaterPDF")
        .then(({ imageExists }) => {
          imageExists(this.props, langSel, beerTranslate, photos, prizes, segmentActive);
          //console.log("paso la llamada a la funcion imageexist");
          setTimeout(() => {
            this.setState({ showSpinner: false });
          }, 12000);

        })
        .catch((err) => {
          // Handle failure
          console.log("err", err);
        });
    }
  };

  handleCollapsibleMenu(e) {
    this.setState({
      menuItems: e.target.textContent,
    });
  }

  render() {

    //console.log("prizes", this.state.prizes);
    const { routerProps, beers, languages, brands, langTitles } = this.props;

    const beerID = routerProps.match.params.beerID;

    //console.log(beers);
    const detatil = beers.filter((item) => item.ID === beerID);
    const moreDataDetail = this.state.moreData.filter(
      (item) => item.BeerID === beerID
    );
    const prizesDetail = this.state.prizes.filter(
      (item) => item.BeerID === beerID
    );
    const photosDetail = this.state.photos.filter(
      (item) => item.BeerID === beerID
    );

    const beerDetail = detatil[0];
    if (beerDetail != undefined) {
      beerDetailSegment = beerDetail;
    }

    //console.log(beerDetail);
    //console.log(moreDataDetail);

    const segmentColor = this.state.segmentColor;
    const segmentActive = this.state.segmentActive;
    const segmentName = this.state.segmentName;
    const titlesFilter = langTitles.filter(
      (i) => i.ID === this.state.langSelect
    ); //filtrar el array del json por lo que pincha el usuario en el menu de idiomas
    console.log(beerDetail);
    //console.log(beerDetail.esAgua);
    if (beerDetail) {
      if (beerDetail.esAgua){
        esAgua = beerDetail.esAgua == "True" ? "True" : "False";
      }else{
        esAgua = "False";
      }
    } else {
      esAgua = "False";
    }

    return (
      <div className="beer">
        <Header />
        <Menu
          toggleMenuBtn={this.toggleMenuBtn}
          burguerMenu={this.state.burguerMenu}
          changeLanguage={this.changeLanguage}
          languages={languages}
          downloadPDF={this.downloadPDF}
          moreDataDetail={moreDataDetail}
          handleCollapsibleMenu={this.handleCollapsibleMenu}
          titlesFilter={titlesFilter}
          beerDetail={beerDetail}
          prizesDetail={prizesDetail}
        />
        <div>
          {detatil[0] ? (esAgua == "False"
            ? (
              
              <Description
                ref={this.child}
                closeMenu={this.closeMenu}
                burguerMenu={this.state.burguerMenu}
                beerDetail={beerDetail}
                moreDataDetail={moreDataDetail}
                langSelect={this.state.langSelect}
                prizesDetail={prizesDetail}
                photosDetail={photosDetail}
                brands={brands}
                segmentColor={segmentColor}
                segmentActive={segmentActive}
                segmentName={segmentName}
                titlesFilter={titlesFilter}
                showSpinner={this.state.showSpinner}
                menuItems={this.state.menuItems}
              />
            ) : (
              <DescriptionWater
                ref={this.child}
                closeMenu={this.closeMenu}
                burguerMenu={this.state.burguerMenu}
                beerDetail={beerDetail}
                moreDataDetail={moreDataDetail}
                langSelect={this.state.langSelect}
                photosDetail={photosDetail}
                prizesDetail={prizesDetail}
                brands={brands}
                segmentColor={segmentColor}
                segmentActive={segmentActive}
                segmentName={segmentName}
                titlesFilter={titlesFilter}
                showSpinner={this.state.showSpinner}
                menuItems={this.state.menuItems}
              />
            )
          ) : (
            <div className="description">
              <p>Esta página no existe</p>
              <Link to="/">Volver al listado</Link>
            </div>
          )}
          <div className="beer__sheet"></div>
        </div>
      </div>
    );
  }
}

export default BeerData;
